<template>
  <div data-cy="checkout-cart-summary" class="CheckoutCartSummary">
    <h2 class="text-left font-bold text-lg-1 font-body">
      {{ $t('products') }}
    </h2>
    <p class="text-gray-90 mt-2 text-left">{{ $t('productsCount', { count }) }}</p>

    <ul class="CheckoutCartSummary__List mt-6">
      <li
        v-for="{
          id,
          quantity,
          brand,
          name,
          totalPrice,
          totalDiscount,
          image,
          oldPrice,
          stock,
          giftBoxMessage,
          url,
        } in items"
        :key="id"
        class="CartItem"
      >
        <div class="flex items-center space-x-6 w-full">
          <AppLink :to="`/${url}`" class="CartItem__image bg-primary-100 relative py-2">
            <NuxtImg
              v-if="image"
              :src="image?.src || ''"
              :alt="image?.alt || ''"
              format="webp"
              width="200"
              height="200"
            />
          </AppLink>
          <div class="flex justify-between w-full">
            <div class="flex flex-col w-full">
              <AppLink :to="`/${url}`" class="CartItem__name justify-self-start">
                <span v-if="brand" data-attr="cartItem__brandName__txt" class="block font-semibold text-sm mb-1">{{
                  brand
                }}</span>
                <span data-attr="cartItem__productName__txt" class="text-sm md:text-base">{{ name }}</span>
                <span v-if="!stock" class="block mt-1 text-sm text-red-500">{{ $t('outOfStockLabel') }}</span>
                <span v-if="stock && stock < quantity" class="block mt-1 text-sm text-primary-500">{{
                  $t('qtyUnavailableLabel')
                }}</span>
              </AppLink>
              <div class="w-full mt-2 flex gap-x-4">
                <div class="w-full md:flex items-center justify-between">
                  <p class="flex items-center gap-x-1.5">
                    <span class="text-primary-1-60 font-medium">{{ $t('qty') }}.</span>
                    <span class="font-medium">{{ quantity }}</span>
                  </p>
                  <div class="flex items-center space-x-5">
                    <p
                      v-if="oldPrice && oldPrice > Number(totalPrice || 0) - Number(totalDiscount || 0)"
                      class="text-xs text-primary-2-04"
                    >
                      <Money class="line-through" :value="oldPrice" />
                    </p>
                    <p class="font-medium text-primary-1-100">
                      <Money :value="Number(totalPrice || 0) - Number(totalDiscount || 0)" />
                    </p>
                  </div>
                </div>
              </div>

              <section v-if="giftBoxMessage && giftBoxMessage !== 'N/A'" class="flex items-center">
                <header class="text-sm text-gray-500 mr-2">{{ $t('customMessage') }}</header>

                <div class="flex items-center oneLine">
                  <p class="">"{{ giftBoxMessage }}"</p>
                </div>
              </section>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import type { CartItem } from '@robustastudio/e-commerce/common';

defineComponent({
  name: 'CartSummary',
});

const { t: $t } = useI18n({ useScope: 'local' });

defineProps({
  items: {
    type: Array as PropType<CartItem[] | []>,
    default: () => [],
  },
  count: {
    type: Number,
    default: 0,
  },
});
</script>

<i18n>
{
  "en": {
    "products": "Products In Cart ",
    "qty": "Qty",
    "purchaseItems": "Purchase Items",
    "productsCount": "You have {count} product(s) in your order",
    "customMessage": "CUSTOM MESSAGE"
  },
  "ar": {
    "products": "المنتجات في عربة التسوق",
    "purchaseItems": "المنتجات المشتراه",
    "productsCount": "لديك {count} منتج فى طلبك ",
    "qty": "الكمية",
    "customMessage": "رسالة مخصصة"
  }
}
</i18n>

<style lang="postcss" scoped>
.CheckoutCartSummary {
  @apply px-[30px] border border-primary-2-02 border-t-transparent rounded-b-3xl py-10;

  &__List {
    li + li {
      margin-top: 10px;
    }
  }
}

@screen lg {
  .CheckoutCartSummary {
    @apply py-8;
    &::before {
      content: unset;
    }
  }
}

.CartItem {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;

  &:not(:last-child) {
    @apply flex flex-col;
    &::after {
      @apply bg-gray-100 my-7;
      content: '';
      width: 100%;
      height: 1px;
    }
  }

  &__image {
    @apply flex-shrink-0 bg-white rounded-md;
    width: 62px;

    img {
      @apply object-contain w-full h-full;
    }
  }

  &__name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

@screen md {
  .CartItem {
    grid-template-columns: 1fr;
    column-gap: 15px;

    &__image {
      width: 100px;
    }
  }
}
.oneline {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
