<template>
  <PaymentSummary
    :total="disableShippingFees && shippingFees ? total - shippingFees : total"
    :subtotal="subtotal"
    :discounts="discounts"
    class="rounded-none rounded-t-3xl"
    :shipping-fees="disableShippingFees ? null : shippingFees ? shippingFees : 0"
    :payment-fees="paymentFees"
    inverted
  >
    <template #cta-placeholder>
      <slot />
    </template>

    <template #extensions>
      <slot name="extensions" />
    </template>
  </PaymentSummary>
</template>

<script setup lang="ts">
defineProps({
  disableShippingFees: {
    type: Boolean,
    default: false,
  },
  paymentFees: {
    type: Object as PropType<{
      title: string;
      fees: number;
    }>,
    default: () => {},
  },
});
defineComponent({
  name: 'CheckoutPaymentSummary',
});
const { total, subtotal, shippingFees, discounts } = useCartAttributes();
</script>

<style lang="postcss" scoped>
.CheckoutPaymentSummary {
  @apply bg-primary-100 py-8;
}
</style>

<i18n>
{
  "en": {
    "summary": "Payment Summary",
    "subtotal": "Subtotal",
    "total": "Total",
    "shippingFees": "Shipping Fees",
    "taxesIncluded": "All taxes are included",
    "proceed": "Proceed to Payment"
  },
  "ar": {
    "summary": "تفاصيل الطلب",
    "subtotal": "الاجمالي الفرعي",
    "total": "الاجمالي",
    "shippingFees": "مصاريف الشحن",
    "taxesIncluded": "يشمل قيمه الضرائب"
  }
}
</i18n>
